@use '@angular/material' as mat;
@import '@angular/material/theming';

@function custom-foreground($color) {
    @return (
        base:              $color,
        text:              $color,
        divider:           rgba($color, 0.12),
        dividers:          rgba($color, 0.12),
        disabled:          rgba($color, 0.38),
        disabled-button:   rgba($color, 0.26),
        disabled-text:     rgba($color, 0.38),
        elevation:         rgba($color, 0.38),
        hint-text:         rgba($color, 0.38),
        secondary-text:    rgba($color, 0.54),
        icon:              rgba($color, 0.54),
        icons:             rgba($color, 0.54),
        slider-off:        rgba($color, 0.26),
        slider-off-active: rgba($color, 0.38),
        slider-min:        rgba($color, 0.87)
    );
};

// Generated here: http://mcg.mbitson.com/
$naue-palette-primary: (
    50 : #e6f5ee,
    100 : #bfe6d4,
    200 : #95d6b8,
    300 : #6bc59b,
    400 : #4bb885,
    500 : #2bac70,
    600 : #26a568,
    700 : #209b5d,
    800 : #1a9253,
    900 : #108241,
    A100 : #b5ffd2,
    A200 : #82ffb2,
    A400 : #4fff93,
    A700 : #36ff83,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$naue-palette-accent: (
    50 : #f3e0e7,
    100 : #e0b3c3,
    200 : #cb809c,
    300 : #b64d74,
    400 : #a72656,
    500 : #970038,
    600 : #8f0032,
    700 : #84002b,
    800 : #7a0024,
    900 : #690017,
    A100 : #ff98a6,
    A200 : #ff657a,
    A400 : #ff324e,
    A700 : #ff1938,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$naue-portal-primary: mat.define-palette($naue-palette-primary);
$naue-portal-accent: mat.define-palette($naue-palette-accent);//, A200, A100, A400);
$naue-portal-warn: mat.define-palette(mat.$red-palette);

$primary-color: mat.get-color-from-palette($naue-portal-primary);

$naue-portal-theme: mat.define-light-theme($naue-portal-primary, $naue-portal-accent, $naue-portal-warn);

// Override foreground color
$naue-text-color: #273c31;
$foreground: custom-foreground($naue-text-color);
$naue-portal-theme: map-merge($naue-portal-theme, (foreground: $foreground));

// Override typography
$naue-typography-small: mat.define-typography-config(
    $font-family: 'Metropolis,Helvetica,Arial,sans-serif',
    $body-1: mat.define-typography-level(16px, 1.3, 300),
    $body-2: mat.define-typography-level(16px, 1.3, 400),
    $headline: mat.define-typography-level(36px, 1.2, 700, 'Halvar,Helvetica,Arial,sans-serif'),
    $title: mat.define-typography-level(26px, 1.2, 400, 'Halvar,Helvetica,Arial,sans-serif'),
    $subheading-2: mat.define-typography-level(20px, 1.2, 400, 'Halvar,Helvetica,Arial,sans-serif'),
    $subheading-1: mat.define-typography-level(18px, 1.2, 400, 'Halvar,Helvetica,Arial,sans-serif'),
);
$naue-typography-large: mat.define-typography-config(
    $font-family: 'Metropolis,Helvetica,Arial,sans-serif',
    $body-1: mat.define-typography-level(18px, 1.3, 300),
    $body-2: mat.define-typography-level(18px, 1.3, 400),
    $headline: mat.define-typography-level(52px, .962, 400, 'Halvar,Helvetica,Arial,sans-serif'),
    $title: mat.define-typography-level(30px, 1.2, 400, 'Halvar,Helvetica,Arial,sans-serif'),
    $subheading-2: mat.define-typography-level(24px, 1.25, 400, 'Halvar,Helvetica,Arial,sans-serif'),
    $subheading-1: mat.define-typography-level(20px, 1.2, 400, 'Halvar,Helvetica,Arial,sans-serif'),
);