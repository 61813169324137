@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './palette.scss';
@import './variables.scss'; 


@include mat.core(); // Attention: only once in the whole app!

@include mat.all-component-typographies($naue-typography-small);
@media (min-width: 480px) {
    @include mat.all-component-typographies($naue-typography-large);
}

@include mat.all-component-themes($naue-portal-theme);


// Initialize flag icons
@import "node_modules/flag-icons/sass/variables";
$flag-icons-included-countries: 'de', 'gb', 'fr';
@import "node_modules/flag-icons/sass/flag-icons-base";
@import "node_modules/flag-icons/sass/flag-icons-list";

*, *::before, *::after {
    box-sizing: border-box;
}

html, body { 
    height: 100%; 
}

body {
    margin: 0;
    padding: 0;
}

.table-responsive {
    overflow-x: auto;
}

table {
    width: 100%;

    &.narrow {
        th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type  {
            padding-left: 0;
        }
        
        th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type  {
            padding-right: 0;
        }
    }
}

th.mat-header-cell {
    font-weight: bold;
}

a {
    text-decoration: none;
    color: $naue-text-color;
    
    &.underlined {
        text-decoration: underline;
    }
}

.row {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;

    &.small {
        max-width: 500px;
    }

    &.medium {
        max-width: 800px;
    }

    &.large {
        max-width: 100%;
    }

    &:last-of-type {
        padding-bottom: 30px;
    }
}

.row, .row-break {
    padding-top: 30px;
}

.fill {
    width: 100%;
}

.banner {
    height: 200px;
    background-color: lightgray;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.flex-fill {
    flex: 1;
}

.text-center {
    text-align: center;
}


.mat-form-field-prefix,
.mat-form-field-suffix {
    align-self: flex-end;
}

.mat-form-field.no-padding-bottom > .mat-form-field-wrapper {
    padding-bottom: 0;
}

.smaller .mat-form-field-infix {
    width: unset!important;
}

.icon-container {
    display: flex;
    align-items: center;

    &>.mat-icon {
        margin-right: .3rem;
    }
}

.mat-card-actions.align,
.mat-card-content.align {
    margin-left: 1rem;
    margin-right: 1rem;
}

.card {    
    border: 1px solid $card-border-color;
    border-radius: $card-border-radius;

    &__header {
        border-bottom: 1px solid $card-header-border-color;
        padding: .75rem 1.25rem;
    }

    &__body {
        padding: 1.25rem;
    }
}

.clickable {
    cursor: pointer;
}

@media ($bp-large-query) {
    .banner {
        height: 300px;
    }
}

tr.clickable {
    cursor: pointer;

    &:hover {
        background-color: lightgray;
    }
}

.mat-radio-button ~ .mat-radio-button {
    margin-left: 1rem;
}

.naue-snackbar-panel {
    background: white;
    color: black;
    border: 2px solid $primary-color;

    .mat-simple-snackbar-action {
        color: $primary-color;
    }
}

.dialog-actions-container {
    margin: -20px -20px 0 0;
    display: flex;
    justify-content: flex-end;
}

.info-bubble-container {
    display: flex;

    &.centered {
        justify-content: center;
        align-items: center;
    }

    &.align-end {
        justify-content: flex-end;
    }
}

.mat-tab-group.bold-tabs .mat-tab-label-content {
    font-weight: bold;
}

.mat-tab-group.wrap-tab-label .mat-tab-label {
    min-height: 48px;
    height: auto;
}

.mat-tab-group.wrap-tab-label .mat-tab-label-content {
    white-space: pre-line;
}

.mat-checkbox-layout {
  white-space: normal !important;
}