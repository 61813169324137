@font-face {
    font-display: swap;
    font-family: Halvar;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Halvar-Breitschrift/Halvar-Breitschrift-Regular.eot'); /* IE9 Compat Modes */
    src: local('Halvar'),
        url('/assets/fonts/Halvar-Breitschrift/Halvar-Breitschrift-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Halvar-Breitschrift/Halvar-Breitschrift-Regular.woff') format('woff'),
        url('/assets/fonts/Halvar-Breitschrift/Halvar-Breitschrift-Regular.woff2') format('woff2');
}
