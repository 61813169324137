@font-face {
    font-display: swap;
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
        local('MaterialIcons-Regular'),
        url('/assets/fonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
        url('/assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons Outlined'),
        local('MaterialIcons-Outlined'),
        url('/assets/fonts/MaterialIcons/MaterialIconsOutlined-Regular.woff2') format('woff2'),
        url('/assets/fonts/MaterialIcons/MaterialIconsOutlined-Regular.otf') format('opentype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
  
  .material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }